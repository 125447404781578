import { Page, PageContent } from './Page';
import { Sidebar } from '../Sidebar';
import { PrimaryPanel } from '../Panel';

import logo from '../../assets/mvm-logo.png';

export function AboutUsPage() {
    return <Page>
        <Sidebar>
            <h1>Rólunk</h1>
            <img src={logo} alt="V-GO logó" />
        </Sidebar>
        <PageContent noPadding>
            <PrimaryPanel title={<>Magyar Innováció és Hatékonyság Nonprofit Kft. / Virtuális Erőmű Program<sup>TM</sup></>} style={{ width: 750 }}>
                <p>A Kelet-közép Európa legnagyobb, Európai Bizottsági díjas, és az ENSZ klímacsúcson is mintaprojektként bemutatott Virtuális Erőmű Program (VEP) a Magyar Innováció és Hatékonyság (Mi6) Nkft. szakembereinek segítségével kerül – a tagok megtakarításainak köszönhetően – megvalósításra. Lsd. bővebben is: <a href="https://virtualiseromu.hu" target="_blank" rel="noreferrer">https://virtualiseromu.hu</a>.</p>
                <p>A VEP már ma is minden évben annyi energiát takarít meg Magyarországon, amivel a 4. legnagyobb villamoserőmű lehetne, ha meg kellett volna épülnie. De szerencsére – köszönhetően a VEP fenntarthatósági közösségének, és a mindenki által a VEP-hez hozzátett kisebb – és nagyobb „üvegtéglákba” foglalt erőfeszítéseknek – sosem kell még egy ilyen nagy, környezetszennyező erőműnek fizikailag is felépülnie.</p>
                <p>A Mi6 csapata fenntartható, társadalmilag felelős programokat valósít meg a teremtett világ és a jövő generációk védelmében. Preventív szemlélettel az energia- és anyagtakarékos, és a planetáris egészségre orientáló fókusszal – építve a virtuális világ és a fiatalok támogatására. Lsd. bővebben is: <a href="http://hello.mi6.hu" target="_blank" rel="noreferrer">http://hello.mi6.hu</a>.</p>
                <p>„Egyre közelebbinek érezzük annak a célnak az elérését, amikor kijelenthetjük majd: minden 10. magyar ember közvetlenül és minden 3. magyar legalább közvetett módon aktív részese lesz a világ lakosságarányosan legjobban kiterjedt energia-hatékonysági, takarékossági és zöld energetikai programjának” – emelte ki Molnár Ferenc PhD, tulajdonos és ügyvezető (<a href="https://www.molnarferencphd.hu" target="_blank" rel="noreferrer">https://www.molnarferencphd.hu</a>). „Nemcsak minden óvodás és iskolás számára lesz elérhető és megismerhető már 2021-től – digitalizált módon is – minden tudás és eredmény, amelyet az utóbbi 10 év aktív szakmai munkája révén összegyűjtöttünk, de magyarok millióinak életét szeretnénk hamarosan megváltoztatni ingatlanjuk és háztartási eszközeik komplex energetikai korszerűsítésének révén is – ezzel megvalósítva a fenntartható és minden eddiginél nagyobb rezsicsökkentést Magyarországon.”</p>
                <p>VEP – V – GO: We go! Mi megyünk, gyertek Ti is velünk!</p>
            </PrimaryPanel>
        </PageContent>
    </Page>;
}
