import {
    HashRouter as Router,
    Switch,
    Route
} from 'react-router-dom';
import moment from 'moment';
import setupMomentDurationFormat from 'moment-duration-format';

import { ProtectedRoute } from './ProtectedRoute';
import { YouTubePlayerApiProvider } from './YouTubePlayerApiProvider';
import { WelcomePage } from './pages/WelcomePage';
import { LoginPage } from './pages/LoginPage';
import { PasswordResetPage } from './pages/PasswordResetPage';
import { RegistrationPage } from './pages/RegistrationPage';
import { HomePage } from './pages/HomePage';
import { VideoPage } from './pages/VideoPage';
import { SettingsPage } from './pages/SettingsPage';
import { SponsorsPage } from './pages/SponsorsPage';
import { SharePage } from './pages/SharePage';
import { LeaderboardPage } from './pages/LeaderboardPage';
import { AboutUsPage } from './pages/AboutUsPage';
import { FAQPage } from './pages/FAQPage';
import { SurveyPage } from './pages/SurveyPage';
import { SupportPage } from './pages/SupportPage';
import { QuestPage } from './pages/QuestPage';

setupMomentDurationFormat(moment);

function App() {
    return <YouTubePlayerApiProvider>
        <Router>
            <Switch>
                <Route path="/welcome" component={WelcomePage} />
                <Route path="/login" component={LoginPage} />
                <Route path="/password-reset" component={PasswordResetPage} />
                <Route path="/registration" component={RegistrationPage} />
                <ProtectedRoute path="/" exact>
                    <HomePage />
                </ProtectedRoute>
                <ProtectedRoute path="/video/:questId">
                    <VideoPage />
                </ProtectedRoute>
                <ProtectedRoute path="/settings">
                    <SettingsPage />
                </ProtectedRoute>
                <ProtectedRoute path="/sponsors">
                    <SponsorsPage />
                </ProtectedRoute>
                <ProtectedRoute path="/share">
                    <SharePage />
                </ProtectedRoute>
                <ProtectedRoute path="/leaderboard">
                    <LeaderboardPage />
                </ProtectedRoute>
                <ProtectedRoute path="/about-us">
                    <AboutUsPage />
                </ProtectedRoute>
                <ProtectedRoute path="/faq">
                    <FAQPage />
                </ProtectedRoute>
                <ProtectedRoute path="/survey">
                    <SurveyPage />
                </ProtectedRoute>
                <ProtectedRoute path="/support">
                    <SupportPage />
                </ProtectedRoute>
                <ProtectedRoute path="/quest/:questId">
                    <QuestPage />
                </ProtectedRoute>
            </Switch>
        </Router>
    </YouTubePlayerApiProvider>;
}

export default App;
