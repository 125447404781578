export function PrimaryPanel({ title, children, ...props }) {
    return <div className="panel panel--primary" {...props}>
        {title ? <h1 className="panel__title">{title}</h1> : null}
        {children}
    </div>;
}

export function SecondaryPanel({ children }) {
    return <div className="panel panel--secondary">
        {children}
    </div>;
}
