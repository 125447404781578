import { useCallback, useEffect, useReducer } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle, faFacebookF } from '@fortawesome/free-brands-svg-icons';

import { Page, PageContent } from './Page';
import { Sidebar } from '../Sidebar';
import { PrimaryPanel } from '../Panel';
import { Row, Column } from '../Row';
import { HorizontalLine } from '../HorizontalLine';
import { PrimaryButton } from '../PrimaryButton';
import { useFirebase } from '../../hooks/useFirebase';
import { useSetUser } from '../../hooks/useUser';

import logo from '../../assets/mvm-logo.png';
import { getUserDetails } from '../functions';

const ActionType = {
    SET_EMAIL: 'SET_EMAIL',
    SET_PASSWORD: 'SET_PASSWORD',
    START_LOADING: 'START_LOADING',
    STOP_LOADING: 'STOP_LOADING',
    SET_ERROR: 'SET_ERROR'
};
const defaultError = 'Váratlan hiba, kérlek próbáld újra később!';

function reduceAction(state, action) {
    switch (action.type) {
        case ActionType.SET_EMAIL:
            return {
                ...state,
                email: action.email
            };

        case ActionType.SET_PASSWORD:
                return {
                    ...state,
                    password: action.password
                };

        case ActionType.START_LOADING:
            return {
                ...state,
                loading: true,
                error: null
            };

        case ActionType.STOP_LOADING:
            return {
                ...state,
                loading: false
            };

        case ActionType.SET_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error ?? defaultError
            };

        default:
            throw new Error('Unexpected action type ' + action.type);
    }
}

const initialState = {
    loading: true,
    error: null,
    email: '',
    password: ''
};

export function LoginPage() {
    const { auth, login, loginWithGoogle, loginWithFacebook } = useFirebase();
    const setUser = useSetUser();
    const history = useHistory();
    const location = useLocation();
    const referrer = location?.state?.referrer ?? '/';
    const handleSuccess = useCallback(async user => {
        setUser(user);

        const idToken = await user.getIdToken();
        const details = await getUserDetails(idToken);

        history.push(details ? referrer : { pathname: '/registration', state: { email: user.email } });
    }, [setUser, history, referrer]);
    const [state, dispatch] = useReducer(reduceAction, initialState);
    const startLoading = useCallback(() => dispatch({ type: ActionType.START_LOADING }), []);
    const stopLoading = useCallback(() => dispatch({ type: ActionType.STOP_LOADING }), []);
    const setError = useCallback(error => dispatch({ type: ActionType.SET_ERROR, error }), []);
    const setEmail = useCallback(event => dispatch({ type: ActionType.SET_EMAIL, email: event.target.value }), []);
    const setPassword = useCallback(event => dispatch({ type: ActionType.SET_PASSWORD, password: event.target.value }), []);
    const handleLogin = useCallback(async event => {
        event.preventDefault();

        if (!state.email.trim() || !state.password.trim()) {
            setError('Az e-mail cím és jelszó mezők kitöltése kötelező!');
            return;
        }

        startLoading();

        try {
            await login(state.email, state.password);
        }
        catch (error) {
            if (error.code === 'auth/invalid-email') {
                setError('Érvénytelen e-mail cím!');
            }
            else if (error.code === 'auth/user-not-found' || error.code === 'auth/wrong-password') {
                setError('Rossz e-mail cím és jelszó páros!');
            }
            else {
                setError();
            }
        }
    }, [state.email, state.password, setError, startLoading, login]);
    const handleLoginWithProvider = useCallback(async provider => {
        if (state.loading) {
            return;
        }

        startLoading();

        try {
            await provider();
        }
        catch (error) {
            if (error.code !== 'auth/popup-closed-by-user') {
                setError();
            }
            else {
                stopLoading();
            }
        }
    }, [state.loading, startLoading, setError, stopLoading]);
    const handleLoginWithGoogle = useCallback(() => handleLoginWithProvider(loginWithGoogle), [handleLoginWithProvider, loginWithGoogle]);
    // const handleLoginWithFacebook = useCallback(() => handleLoginWithProvider(loginWithFacebook), [handleLoginWithProvider, loginWithFacebook]);

    useEffect(() =>
        auth.onAuthStateChanged(user => {
            if (user) {
                handleSuccess(user);
            }
            else {
                stopLoading();
            }
        }), [auth, handleSuccess, stopLoading]);

    return <Page>
        <Sidebar>
            <img src={logo} alt="V-GO logó" />
        </Sidebar>
        <PageContent noPadding>
            <PrimaryPanel title="Bejelentkezés">
                {state.error ? <p className="error-message">{state.error}</p> : null}
                <form onSubmit={handleLogin}>
                    <Row style={{ marginBottom: 15 }}>
                        <Column span={1}>
                            <input type="text" placeholder="E-mail cím" value={state.email} onChange={setEmail} />
                        </Column>
                    </Row>
                    <Row style={{ marginBottom: 30 }}>
                        <Column span={1}>
                            <input type="password" placeholder="Jelszó" value={state.password} onChange={setPassword} />
                        </Column>
                    </Row>
                    <Row style={{ marginBottom: 30 }}>
                        <Column span={1}>
                            <Link to="/password-reset">Elfelejtetted a jelszavad?</Link>
                        </Column>
                    </Row>
                    <Row style={{ marginBottom: 30 }}>
                        <Column span={1}>
                            <PrimaryButton loading={state.loading}>Bejelentkezés</PrimaryButton>
                        </Column>
                    </Row>
                    <Row style={{ marginBottom: 30 }}>
                        <Column span={1}>vagy</Column>
                    </Row>
                    <Row style={{ marginBottom: 30 }}>
                        <Column span={1}>
                            <button type="button" className="secondary-button" onClick={handleLoginWithGoogle}>
                                <FontAwesomeIcon icon={faGoogle} />
                                {' Google'}
                            </button>
                        </Column>
                        {/*<Column span={1}>*/}
                        {/*    <button type="button" className="secondary-button" onClick={handleLoginWithFacebook}>*/}
                        {/*        <FontAwesomeIcon icon={faFacebookF} />*/}
                        {/*        {' Facebook'}*/}
                        {/*    </button>*/}
                        {/*</Column>*/}
                    </Row>
                    <HorizontalLine />
                    <Row>
                        <Column span={1}>
                            <Link to="/registration">Még új vagy? Regisztálj most!</Link>
                        </Column>
                    </Row>
                </form>
            </PrimaryPanel>
        </PageContent>
    </Page>;
}
