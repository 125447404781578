import { useCallback, useEffect, useReducer } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { Page, PageContent } from './Page';
import { Sidebar } from '../Sidebar';
import { useUser } from '../../hooks/useUser';
import { getUserDetails, startQuest } from '../functions';
import { Row, Column } from '../Row';
import { YouTubePlayer } from '../YouTubePlayer';
import { SecondaryPanel } from '../Panel';
import { PrimaryButton } from '../PrimaryButton';
import { Popup } from '../Popup';

import cameraRatAnimation from '../../assets/animations/camera-rat.gif';

const ActionType = {
    SET_QUEST: 'SET_QUEST',
    SET_VIDEO_ENDED: 'SET_VIDEO_ENDED',
    SHOW_POPUP: 'SHOW_POPUP',
    HIDE_POPUP: 'HIDE_POPUP'
};

function reduceAction(state, action) {
    switch (action.type) {
        case ActionType.SET_QUEST:
            return {
                ...state,
                quest: action.quest
            };

        case ActionType.SET_VIDEO_ENDED:
            return {
                ...state,
                videoEnded: true
            };

        case ActionType.SHOW_POPUP:
            return {
                ...state,
                popupVisible: true
            };

        case ActionType.HIDE_POPUP:
            return {
                ...state,
                popupVisible: false
            };

        default:
            throw new Error('Unexpected action type ' + action.type);
    }
}

const initialState = {
    quest: null,
    videoEnded: false,
    popupVisible: false
};

export function VideoPage() {
    const user = useUser();
    const { questId } = useParams();
    const [state, dispatch] = useReducer(reduceAction, initialState);
    const setQuest = useCallback(quest => dispatch({ type: ActionType.SET_QUEST, quest }), []);
    const setVideoEnded = useCallback(() => dispatch({ type: ActionType.SET_VIDEO_ENDED }), []);
    const history = useHistory();
    const location = useLocation();
    const showPopup = useCallback(() => dispatch({ type: ActionType.SHOW_POPUP }), []);
    const goToQuestPage = useCallback(() => {
        if (!state.videoEnded) {
            showPopup();
            return;
        }

        user.getIdToken()
            .then(idToken => startQuest(idToken, questId))
            .then(() =>
                history.push({ pathname: '/quest/' + questId, state: { referrer: location.pathname } }));
    }, [state.videoEnded, showPopup, user, history, questId, location]);
    const hidePopup = useCallback(() => dispatch({ type: ActionType.HIDE_POPUP }), []);

    useEffect(() => {
        let mounted = true;

        if (user) {
            user.getIdToken()
                .then(getUserDetails)
                .then(details => {
                    if (mounted) {
                        const quests = details?.quests ?? [];
                        const quest = quests.find(quest => quest.id === questId);

                        setQuest(quest);

                        if (quest.videoId === null) {
                            setVideoEnded();
                        }
                    }
                });
        }

        return () => {
            mounted = false;
        };
    }, [user, questId, setQuest])

    return <Page loading={!state.quest}>
        <Sidebar>
            <h1>{state.quest?.title}</h1>
            <img src={cameraRatAnimation} alt="Animáció" />
        </Sidebar>
        <PageContent szechenyiLogo={state.quest?.szechenyiLogo}>
            <div className="video-page-content">
                {state.quest?.videoId && <Row style={{marginBottom: 50}}>
                    <Column span={1}>
                        <YouTubePlayer videoId={state.quest?.videoId} onVideoEnded={setVideoEnded}/>
                    </Column>
                </Row>}
                <Row>
                    <Column span={1}>
                        <SecondaryPanel>
                            <h1>{state.quest?.title}</h1>
                            <p>{state.quest?.text}</p>
                            <PrimaryButton className={!state.videoEnded  ? 'disabled' : undefined} onClick={goToQuestPage}>Tovább</PrimaryButton>
                        </SecondaryPanel>
                    </Column>
                </Row>
            </div>
            <Popup visible={state.popupVisible} onCancel={hidePopup} onContinue={hidePopup}>A továbblépéshez először nézd végig a videót!</Popup>
        </PageContent>
    </Page>;
}
