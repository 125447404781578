import { useCallback } from 'react';

import { Page, PageContent } from './Page';
import { Sidebar } from '../Sidebar';
import { Row, Column } from '../Row';
import { PrimaryButton } from '../PrimaryButton';

import handsIcon from '../../assets/hands-icon.svg';
import mvmLogo from '../../assets/sponsors/mvm-logo.svg';
import otpBankLogo from '../../assets/sponsors/otp-bank-nyrt-logo.png';
import proFiliiLogo from '../../assets/sponsors/pro-filii-logo.png';
import mi6NkftLogo from '../../assets/sponsors/mi6-nkft-logo.svg';
import molnarFerencPhdLogo from '../../assets/sponsors/molnar-ferenc-phd-logo.svg';
import ujhazZrtLogo from '../../assets/sponsors/ujhaz-zrt-logo.svg';

function SponsorCard({ logo, name, url }) {
    const openUrl = useCallback(() => window.open(url, '_blank'), [url]);

    return <div className="sponsor-card">
        <Row style={{ marginBottom: 15 }}>
            <Column>
                <img src={logo} alt={name + ' logó'} />
            </Column>
        </Row>
        <Row>
            <Column>
                {name}
            </Column>
        </Row>
        <div className="floating-button-container">
            <Row>
                <Column>
                    <PrimaryButton className="primary-button--white" onClick={openUrl}>Tovább</PrimaryButton>
                </Column>
            </Row>
        </div>
    </div>;
}

function SponsorGrid() {
    return <div className="sponsor-grid">
        <SponsorCard logo={mvmLogo} name="MVM Zrt." url="https://mvm.hu" />
        <SponsorCard logo={otpBankLogo} name="OTP Bank Nyrt." url="https://www.otpbank.hu/portal/hu/Maganszemelyek" />
        <SponsorCard logo={proFiliiLogo} name="Pro Filii Alapítvány" url="https://profilii.hu" />
        <SponsorCard logo={mi6NkftLogo} name="Magyar Innováció és Hatékonyság (MI6) Nonprofit Kft." url="https://www.mi6.hu" />
        <SponsorCard logo={molnarFerencPhdLogo} name="Molnár Ferenc PhD" url="https://www.molnarferencphd.hu" />
        <SponsorCard logo={ujhazZrtLogo} name="Új Ház Zrt." url="https://ujhaz.hu" />
    </div>;
}

export function SponsorsPage() {
    return <Page>
        <Sidebar>
            <h1>Szponzorok</h1>
            <img src={handsIcon} alt="Ikon" />
        </Sidebar>
        <PageContent noPadding>
            <SponsorGrid />
        </PageContent>
    </Page>;
}
