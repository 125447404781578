import { useCallback, useReducer } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Page, PageContent } from './Page';
import { Sidebar } from '../Sidebar';
import { PrimaryPanel, SecondaryPanel } from '../Panel';
import { Row, Column } from '../Row';
import { ProgressBar } from '../ProgressBar';
import { ConditionalContainer } from '../ConditionalContainer';
import { validateEmail, validateBirthYear } from '../../utilities';
import { CitySearchDialog } from '../CitySearchDialog';
import { SchoolSearchDialog } from '../SchoolSearchDialog';
import { PrimaryButton } from '../PrimaryButton';
import { saveUserDetails } from '../functions';
import { useSetUser, useUser } from '../../hooks/useUser';
import { useFirebase } from '../../hooks/useFirebase';

import logo from '../../assets/mvm-logo.png';
import editablePageIcon from '../../assets/editable-page-icon.svg';

const ActionType = {
    SET_LAST_NAME: 'SET_LAST_NAME',
    SET_FIRST_NAME: 'SET_FIRST_NAME',
    SET_EMAIL: 'SET_EMAIL',
    SET_BIRTH_YEAR: 'SET_BIRTH_YEAR',
    SET_PASSWORD: 'SET_PASSWORD',
    GO_TO_NEXT_STEP: 'GO_TO_NEXT_STEP',
    SHOW_CITY_SEARCH_DIALOG: 'SHOW_CITY_SEARCH_DIALOG',
    HIDE_CITY_SEARCH_DIALOG: 'HIDE_CITY_SEARCH_DIALOG',
    SET_CITY: 'SET_CITY',
    SET_TYPE: 'SET_TYPE',
    SHOW_SCHOOL_SEARCH_DIALOG: 'SHOW_SCHOOL_SEARCH_DIALOG',
    HIDE_SCHOOL_SEARCH_DIALOG: 'HIDE_SCHOOL_SEARCH_DIALOG',
    SET_SCHOOL: 'SET_SCHOOL',
    SET_CLASS: 'SET_CLASS',
    SET_INVITE_CODE: 'SET_INVITE_CODE',
    SET_PRIVACY_POLICY: 'SET_PRIVACY_POLICY',
    SET_NEWSLETTER: 'SET_NEWSLETTER',
    SET_ERROR: 'SET_ERROR',
    START_LOADING: 'START_LOADING'
};
const defaultError = 'Váratlan hiba, kérlek próbáld újra később!';

function validateState(state) {
    if (state.step === 0) {
        if (!state.lastName.trim()) {
            return 'A vezetéknév mező kitöltése kötelező!';
        }
        else if (!state.firstName.trim()) {
            return 'A keresztnév mező kitöltése kötelező!';
        }
        else if (!state.birthYear.trim()) {
            return 'Az születési év mező kitöltése kötelező!';
        }
        else if (!validateBirthYear(state.birthYear)) {
            return 'Az születési év érvénytelen!';
        }
        else if (!state.email.trim()) {
            return 'Az e-mail cím mező kitöltése kötelező!';
        }
        else if (!validateEmail(state.email)) {
            return 'Az e-mail cím érvénytelen!';
        }
        else if (!state.defaultEmail) {
            if (!state.password.trim()) {
                return 'A jelszó mező kitöltése kötelező!';
            }
            else if (state.password.length < 6) {
                return 'A jelszónak legalább 6 karakternek kell lennie!';
            }
        }
    }
    else if (state.step === 1) {
        if (!state.city.text) {
            return 'A település mező kitöltése kötelező!';
        }
        else if (state.type.value === 'STUDENT' && !state.school.value) {
            return 'Az iskola mező kitöltése kötelező!';
        }
    }
    else if (state.step === 2) {
        if (!state.privacyPolicy) {
            return 'Az adatkezelési tájékoztató elfogadása kötelező!';
        }
        else if (!state.newsletter) {
            return 'Díjátadón történő kép- és hangfelvételek készítéséhez való hozzájárulás elfogadása kötelező!';
        }
    }

    return null;
}

function reduceAction(state, action) {
    switch (action.type) {
        case ActionType.SET_LAST_NAME:
            return {
                ...state,
                lastName: action.lastName
            };

        case ActionType.SET_FIRST_NAME:
            return {
                ...state,
                firstName: action.firstName
            };

        case ActionType.SET_EMAIL:
            return {
                ...state,
                email: action.email
            };

        case ActionType.SET_BIRTH_YEAR:
            return {
                ...state,
                birthYear: action.birthYear
            };

        case ActionType.SET_PASSWORD:
            return {
                ...state,
                password: action.password
            };

        case ActionType.GO_TO_NEXT_STEP:
            const error = validateState(state);
            
            return {
                ...state,
                error,
                step: error ? state.step : state.step + 1
            };

        case ActionType.SHOW_CITY_SEARCH_DIALOG:
            return {
                ...state,
                citySearchDialogVisible: true
            };

        case ActionType.HIDE_CITY_SEARCH_DIALOG:
            return {
                ...state,
                citySearchDialogVisible: false
            };

        case ActionType.SET_CITY:
            return {
                ...state,
                city: action.city,
                citySearchDialogVisible: false
            };

        case ActionType.SHOW_SCHOOL_SEARCH_DIALOG:
            return {
                ...state,
                schoolSearchDialogVisible: true
            };

        case ActionType.HIDE_SCHOOL_SEARCH_DIALOG:
            return {
                ...state,
                schoolSearchDialogVisible: false
            };

        case ActionType.SET_SCHOOL:
            return {
                ...state,
                school: action.school,
                schoolSearchDialogVisible: false
            };

        case ActionType.SET_TYPE:
            return {
                ...state,
                type: action.typeValue
            };

        case ActionType.SET_CLASS:
            return {
                ...state,
                class: action.class
            };

        case ActionType.SET_INVITE_CODE:
            return {
                ...state,
                inviteCode: action.inviteCode
            };

        case ActionType.SET_PRIVACY_POLICY:
            return {
                ...state,
                privacyPolicy: action.privacyPolicy
            };

        case ActionType.SET_NEWSLETTER:
            return {
                ...state,
                newsletter: action.newsletter
            };

        case ActionType.SET_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error ?? defaultError
            };

        case ActionType.START_LOADING:
            return {
                ...state,
                loading: true,
                error: null
            };

        default:
            throw new Error('Unexpected action type ' + action.type);
    }
}

function initializeState(email) {
    return {
        loading: false,
        error: null,
        step: 0,
        lastName: '',
        firstName: '',
        birthYear: '',
        defaultEmail: email ?? null,
        email: email ?? '',
        password: '',
        citySearchDialogVisible: false,
        city: null,
        type: { value: 'STUDENT', text: 'Diák vagyok' },
        school: null,
        schoolSearchDialogVisible: false,
        class: { value: '0', text: 'Alsó tagozat (1-4. osztály)' },
        inviteCode: '',
        privacyPolicy: true,
        newsletter: false
    };
}

export function RegistrationPage() {
    const location = useLocation();
    const email = location?.state?.email;
    const [state, dispatch] = useReducer(reduceAction, email, initializeState);
    const setLastName = useCallback(event => dispatch({ type: ActionType.SET_LAST_NAME, lastName: event.target.value }), []);
    const setFirstName = useCallback(event => dispatch({ type: ActionType.SET_FIRST_NAME, firstName: event.target.value }), []);
    const setEmail = useCallback(event => dispatch({ type: ActionType.SET_EMAIL, email: event.target.value }), []);
    const setBirthYear = useCallback(event => dispatch({ type: ActionType.SET_BIRTH_YEAR, birthYear: event.target.value }), []);
    const setPassword = useCallback(event => dispatch({ type: ActionType.SET_PASSWORD, password: event.target.value }), []);
    const goToNextStep = useCallback(() => dispatch({ type: ActionType.GO_TO_NEXT_STEP }), []);
    const showCitySearchDialog = useCallback(() => dispatch({ type: ActionType.SHOW_CITY_SEARCH_DIALOG }), []);
    const hideCitySearchDialog = useCallback(() => dispatch({ type: ActionType.HIDE_CITY_SEARCH_DIALOG }), []);
    const setCity = useCallback(city => dispatch({ type: ActionType.SET_CITY, city }), []);
    const showSchoolSearchDialog = useCallback(() => dispatch({ type: ActionType.SHOW_SCHOOL_SEARCH_DIALOG }), []);
    const hideSchoolSearchDialog = useCallback(() => dispatch({ type: ActionType.HIDE_SCHOOL_SEARCH_DIALOG }), []);
    const setSchool = useCallback(school => dispatch({ type: ActionType.SET_SCHOOL, school }), []);
    const setType = useCallback(event => dispatch({ type: ActionType.SET_TYPE, typeValue: { value: event.target.value, text: event.target.selectedOptions[0].innerHTML } }), []);
    const setClass = useCallback(event => dispatch({ type: ActionType.SET_CLASS, class: { value: event.target.value, text: event.target.selectedOptions[0].innerHTML } }), []);
    const setInviteCode = useCallback(event => dispatch({ type: ActionType.SET_INVITE_CODE, inviteCode: event.target.value }), []);
    const setPrivacyPolicy = useCallback(event => dispatch({ type: ActionType.SET_PRIVACY_POLICY, privacyPolicy: event.target.checked }), []);
    const setNewsletter = useCallback(event => dispatch({ type: ActionType.SET_NEWSLETTER, newsletter: event.target.checked }), []);
    const setError = useCallback(error => dispatch({ type: ActionType.SET_ERROR, error }), []);
    const startLoading = useCallback(() => dispatch({ type: ActionType.START_LOADING }), []);
    const user = useUser();
    const history = useHistory();
    const { login } = useFirebase();
    const setUser = useSetUser();
    const handleSubmit = useCallback(async event => {
        event.preventDefault();

        let error;

        if ((error = validateState(state))) {
            setError(error);
            return;
        }

        startLoading();

        const idToken = user ? await user.getIdToken() : null;
        const student = state.type.value === 'STUDENT';
        const details = await saveUserDetails(idToken, {
            lastName: state.lastName,
            firstName: state.firstName,
            email: !idToken ? state.email : null,
            birthYear: state.birthYear,
            password: !idToken ? state.password : null,
            city: state.city.text,
            type: state.type,
            school: student ? { id: state.school.value, name: state.school.text } : null,
            class: student ? { value: +state.class.value, text: state.class.text } : null,
            inviteCode: state.inviteCode,
            privacyPolicy: state.privacyPolicy,
            newsletter: state.newsletter
        });

        if (details.code === 0x0) {
            setError('A megadott e-mail cím már használatban van!');
            return;
        }
        else if (!idToken) {
            const user = await login(state.email, state.password);

            setUser(user);
        }

        history.push('/');
    }, [state, setError, startLoading, user, history, login, setUser]);

    const stepCount = 3;
    const noScroll = state.citySearchDialogVisible || state.schoolSearchDialogVisible;

    return <Page noScroll={noScroll}>
        <Sidebar>
            <img src={logo} alt="V-GO logó" />
        </Sidebar>
        <PageContent noPadding>
            <PrimaryPanel title="Regisztráció">
                <Row style={{ marginBottom: 15 }}>
                    <Column span={1}>{state.step + 1}/{stepCount}</Column>
                </Row>
                <Row style={{ marginBottom: 30 }}>
                    <Column span={1}>
                        <img src={editablePageIcon} alt="Szerkeszthető oldalt ábrázoló ikon" />
                    </Column>
                </Row>
                {state.error ? <p className="error-message">{state.error}</p> : null}
                <SecondaryPanel>
                    <form onSubmit={handleSubmit}>
                        <ConditionalContainer condition={state.step === 0}>
                            <Row style={{ marginBottom: 15 }}>
                                <Column span={1}>
                                    <input type="text" placeholder="Vezetéknév" value={state.lastName} onChange={setLastName} />
                                </Column>
                                <Column span={1}>
                                    <input type="text" placeholder="Keresztnév" value={state.firstName} onChange={setFirstName} />
                                </Column>
                            </Row>
                            <Row style={{ marginBottom: 15 }}>
                                <Column span={1}>
                                    <input type="text" placeholder="Születési év" value={state.birthYear} onChange={setBirthYear} />
                                </Column>
                            </Row>
                            <Row style={{ marginBottom: 15 }}>
                                <Column span={1}>
                                    <input type="text" placeholder="E-mail cím" disabled={!!email} value={state.email} onChange={setEmail} />
                                </Column>
                            </Row>
                            {!email
                                ? <Row style={{ marginBottom: 15 }}>
                                    <Column span={1}>
                                        <input type="password" placeholder="Jelszó" value={state.password} onChange={setPassword} />
                                    </Column>
                                </Row>
                                : null}
                        </ConditionalContainer>
                        <ConditionalContainer condition={state.step === 1}>
                            <Row style={{ marginBottom: 15 }}>
                                <Column span={1}>
                                    <input type="text" placeholder="Település" value={state.city?.text ?? ''} readOnly onFocus={showCitySearchDialog} />
                                </Column>
                            </Row>
                            <Row style={{ marginBottom: 15 }}>
                                <Column span={1}>
                                    <select value={state.type.value} onChange={setType}>
                                        <option value="STUDENT">Diák vagyok</option>
                                        <option value="TEACHER">Tanár vagyok</option>
                                        <option value="OTHER">Egyéb</option>
                                    </select>
                                </Column>
                            </Row>
                            {state.type.value === 'STUDENT'
                                ? <>
                                    <Row style={{ marginBottom: 15 }}>
                                        <Column span={1}>
                                            <input type="text" placeholder="Iskola" value={state.school?.text ?? ''} readOnly onFocus={showSchoolSearchDialog} />
                                        </Column>
                                    </Row>
                                    <Row style={{ marginBottom: 15 }}>
                                        <Column span={1}>
                                            <select value={state.class.value} onChange={setClass}>
                                                <option value="0">Alsó tagozat (1-4. osztály)</option>
                                                <option value="1">Felső tagozat (5-8. osztály)</option>
                                                <option value="2">Középiskola</option>
                                            </select>
                                        </Column>
                                    </Row>
                                </>
                                : null}
                        </ConditionalContainer>
                        <ConditionalContainer condition={state.step === 2}>
                            <Row style={{ marginBottom: 15 }}>
                                <Column span={1}>
                                    <input type="text" placeholder="Meghívó kód (ha kaptál, nem kötelező)" value={state.inviteCode} onChange={setInviteCode} />
                                </Column>
                            </Row>
                            <Row style={{ marginBottom: 15 }}>
                                <Column span={1}>
                                    <label>
                                        <input type="checkbox" checked={state.privacyPolicy} onChange={setPrivacyPolicy} />
                                        {'A regisztráció befejezését megelőzően kérjük olvasd el az '}
                                        <a href="https://www.brizy.cloud/customfile/d29b01211192c286815fe392558bcd20.pdf" target="_blank" rel="noreferrer">Adatkezelési Tájékoztatót</a>
                                        {'. Az adatkezeléssel kapcsolatos további részletes leírást pedig '}
                                        <a href="https://www.brizy.cloud/customfile/9649e76ddea7646d94fa0103779071fe.pdf" target="_blank" rel="noreferrer">ide kattintva</a>
                                        {' találsz.'}
                                    </label>
                                </Column>
                            </Row>
                            <Row style={{ marginBottom: 15 }}>
                                <Column span={1}>
                                    {'Hozzájárulok, hogy az Adatkezelő a jelen Hozzájáruló Nyilatkozatban, valamint a Jelentkezés során megadott, továbbá a Tanulmányi Verseny lebonyolítása során keletkező további személyes adatokat a Tanulmányi Versenyhez kapcsolódóan kezelje. Tudomásul veszem, hogy az Adatkezelő jogosult a Tanulmányi Verseny lebonyolítását és eredményét, a kiskorú Tanulmányi Versenyre való Jelentkezése esetén részvételét és esetleges díjazását dokumentálni, és ennek érdekében az így rögzített adatokat továbbadni a Tanulmányi Verseny További Adatkezelőjének és a Tanulmányi Verseny végrehajtásának ellenőrzését végző Szervezeteknek.'}
                                </Column>
                            </Row>
                            <Row style={{ marginBottom: 15 }}>
                                <Column span={1}>
                                    <label>
                                        <input type="checkbox" checked={state.newsletter} onChange={setNewsletter} />
                                        {' Hozzájárulok, hogy díjazásom esetén nevem, iskolám, és évfolyamom közzétételre kerüljön, valamint a díjátadón rólam kép- és hangfelvétel készüljön és megjelenjen az Adatkezelő tevékenysége, céljai, valamint az Applikáció és a Küldetések népszerűsítése, megismertetése érdekében.'}
                                    </label>
                                </Column>
                            </Row>
                        </ConditionalContainer>
                        <Row style={{ marginBottom: 15 }}>
                            <Column span={1}>
                                <ProgressBar progress={state.step / stepCount} />
                            </Column>
                        </Row>
                        <Row>
                            <Column span={1}>
                                <ConditionalContainer condition={state.step !== 2}>
                                    <button type="button" className="primary-button" onClick={goToNextStep}>Tovább</button>
                                </ConditionalContainer>
                                <ConditionalContainer condition={state.step === 2}>
                                    <PrimaryButton loading={state.loading}>Regisztráció</PrimaryButton>
                                </ConditionalContainer>
                            </Column>
                        </Row>
                    </form>
                </SecondaryPanel>
            </PrimaryPanel>
        </PageContent>
        <CitySearchDialog visible={state.citySearchDialogVisible} onCancel={hideCitySearchDialog} value={state.city} onChange={setCity} />
        <SchoolSearchDialog visible={state.schoolSearchDialogVisible} onCancel={hideSchoolSearchDialog} value={state.school} onChange={setSchool} />
    </Page>;
}
