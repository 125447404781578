import { useEffect, useState } from 'react';

export function YouTubePlayerApiProvider({ children }) {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const script = document.createElement('script');

        script.src = 'https://www.youtube.com/iframe_api';
        script.async = true;
        window.onYouTubeIframeAPIReady = () => setLoaded(true);

        document.body.appendChild(script);
    }, []);

    return loaded ? children : null;
}
