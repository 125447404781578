import { Page, PageContent } from './Page';
import { Sidebar } from '../Sidebar';
import { PrimaryPanel } from '../Panel';
import { PrimaryButton } from '../PrimaryButton';

import logo from '../../assets/mvm-logo.png';

export function SurveyPage() {
    return <Page>
        <Sidebar>
            <h1>Elégedettségi kérdőív</h1>
            <img src={logo} alt="V-GO logó" />
        </Sidebar>
        <PageContent noPadding>
        <PrimaryPanel style={{ width: 750 }}>
                <p>Amennyiben elkészültél a küldetésekkel, kérjük, töltsd ki az alábbi kérdőívet egy perc alatt, mert a véleményeddel rengeteget segítessz nekünk!</p>
                <p>
                    <PrimaryButton onClick={() => window.open('https://app.valuebot.io/survey/f52f104e-d072-481d-a092-88ddaa8a280d', '_blank')}>Kitöltöm</PrimaryButton>
                </p>
            </PrimaryPanel>
        </PageContent>
    </Page>;
}
