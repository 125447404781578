import { useCallback, useEffect, useReducer } from 'react';
import { useLocation, useParams, Redirect, useHistory } from 'react-router-dom';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

import { Page, PageContent } from './Page';
import { Sidebar } from '../Sidebar';
import { useUser } from '../../hooks/useUser';
import { getUserDetails, saveAnswers } from '../functions';
import { PrimaryButton } from '../PrimaryButton';
import { Popup } from '../Popup';

import 'react-circular-progressbar/dist/styles.css';
import winnerRatAnimation from '../../assets/animations/winner-rat.gif';
import { CountDown } from '../CountDown';

const progressBarStyles = buildStyles({
    trailColor: 'rgba(255, 255, 255, 0.25)',
    pathColor: '#3CA874',
    textColor: 'white'
});
const ActionType = {
    SET_INITIAL_STATE: 'SET_INITIAL_STATE',
    TOGGLE_ANSWER: 'TOGGLE_ANSWER',
    START_LOADING: 'START_LOADING',
    SET_EXPIRED: 'SET_EXPIRED',
    SHOW_DETAILS: 'SHOW_DETAILS',
    STEP_QUESTION_INDEX: 'STEP_QUESTION_INDEX'
};
const QuestionState = {
    STARTED: 'STARTED'
};
const getTimeLimit = (quest) => {
    return quest?.timeLimitPerQuestion * 60_000 ?? (quest.version === 1 ? 180_000 : 60_000)
};

function reduceAction(state, action) {
    switch (action.type) {
        case ActionType.SET_INITIAL_STATE:
            return {
                loading: false,
                quest: action.state.quest,
                questionIndex: action.state.questionIndex,
                question: action.state.question,
                selectedAnswerIndicies: []
            };

        case ActionType.TOGGLE_ANSWER:
            return {
                ...state,
                selectedAnswerIndicies: state.expired
                    ? state.selectedAnswerIndicies
                    : (state.question.correctAnswers.length === 1
                        ? [action.answerIndex]
                        : (state.selectedAnswerIndicies.includes(action.answerIndex)
                            ? state.selectedAnswerIndicies.filter(selectedAnswerIndex => selectedAnswerIndex !== action.answerIndex)
                            : [...state.selectedAnswerIndicies, action.answerIndex]))
            };

        case ActionType.START_LOADING:
            return {
                ...state,
                loading: true
            };

        case ActionType.SET_EXPIRED:
            const expired = +moment.utc() - state.question.startTime > getTimeLimit(state.quest);

            return {
                ...state,
                expired,
                selectedAnswerIndicies: expired ? [] : state.selectedAnswerIndicies
            };

        case ActionType.SHOW_DETAILS:
            return {
                ...state,
                questionIndex: 0,
                showDetails: true
            };

        case ActionType.STEP_QUESTION_INDEX:
            const nextQuestionIndex = state.questionIndex + 1;
            const maxQuestionIndex = state.quest.questions.length - 1;
            const canStep = nextQuestionIndex <= maxQuestionIndex;

            return {
                ...state,
                questionIndex: canStep ? nextQuestionIndex : -1,
                showDetails: canStep ? state.showDetails : false
            };

        default:
            throw new Error('Unexpected action type ' + action.type);
    }
}

const initialState = {
    loading: false,
    quest: null,
    questionIndex: -1,
    question: null,
    selectedAnswerIndicies: [],
    expired: false,
    showDetails: false
};

export function QuestPage() {
    const location = useLocation();
    const referrer = location?.state?.referrer ?? null;
    const user = useUser();
    const { questId } = useParams();
    const [state, dispatch] = useReducer(reduceAction, initialState);
    const setInitialState = useCallback(quest => {
        const questionIndex = quest.questions.findIndex(question => !question.state ? !question.answers.length : question.state === QuestionState.STARTED);
        const question = questionIndex === -1 ? quest.questions[quest.questions.length - 1] : quest.questions[questionIndex];

        dispatch({
            type: ActionType.SET_INITIAL_STATE,
            state: {
                quest,
                questionIndex,
                question
            }
        });
    }, []);
    const handleAnswerSelection = useCallback((answerIndex, showDetails) => {
        if (!showDetails) {
            dispatch({ type: ActionType.TOGGLE_ANSWER, answerIndex });
        }
    }, []);
    const startLoading = useCallback(() => dispatch({ type: ActionType.START_LOADING }), []);
    const stepQuestionIndex = useCallback(() => dispatch({ type: ActionType.STEP_QUESTION_INDEX }), []);

    const saveAnswersCallback = useCallback(async () => {
        if (!state?.selectedAnswerIndicies?.length && !state?.expired && !state.showDetails) {
            return;
        }
        else if (state?.showDetails) {
            return stepQuestionIndex();
        }

        startLoading();

        user.getIdToken()
            .then(idToken => saveAnswers(idToken, questId, state?.selectedAnswerIndicies))
            .then(setInitialState);
    }, [state?.selectedAnswerIndicies, state?.expired, startLoading, user, questId, setInitialState, state?.showDetails, stepQuestionIndex]);
    const history = useHistory();
    const goToHomePage = useCallback(() => history.push('/'), [history]);
    const setExpired = useCallback(() => dispatch({ type: ActionType.SET_EXPIRED }), []);
    const showDetails = useCallback(() => dispatch({ type: ActionType.SHOW_DETAILS }), []);

    useEffect(() => {
        let mounted = true;

        user.getIdToken()
            .then(idToken => getUserDetails(idToken))
            .then(details => {
                if (mounted) {
                    const quest = details.quests.find(quest => quest.id === questId);

                    if (quest) {
                        setInitialState(quest);
                    }
                    else {
                        history.replace('/');
                    }
                }
            });

        return () => {
            mounted = false;
        };
    }, [user, questId, setInitialState, history]);

    if (referrer !== '/video/' + questId) {
        return <Redirect to="/" />;
    }

    const completedQuest = state?.questionIndex === -1;
    // const completedQuest = true;
    const progress = completedQuest ? 100 : 100 * state?.questionIndex / state?.quest?.questions?.length;
    const result = 100 * state?.quest?.questions?.reduce((correctAnswerCount, question) =>
        question.answers.length && isEqual(sortBy(question.answers), sortBy(question.correctAnswers)) ? correctAnswerCount + 1 : correctAnswerCount, 0) / state?.quest?.questions?.length;
    const question = state.showDetails ? state?.quest?.questions[state?.questionIndex] : state?.question;
    const selectedAnswerIndices = state?.showDetails
        ? question?.answers
        : state?.selectedAnswerIndicies;
    const popupVisible = completedQuest && !state?.showDetails;

    return <Page loading={!state?.quest} noScroll={popupVisible}>
        <Sidebar>
            <h1>{state?.quest?.title}</h1>
            <h2>{completedQuest ? state?.quest?.questions?.length : state?.questionIndex + 1}/{state?.quest?.questions?.length}</h2>
            <h3>
                {question?.state && !completedQuest && !state?.showDetails ? <CountDown startTime={question?.startTime} duration={getTimeLimit(state?.quest)} onTick={setExpired} /> : null}
            </h3>
            <div style={{ width: 150 }}>
                <CircularProgressbar value={progress} text={progress.toFixed(2) + '%'} styles={progressBarStyles} />
            </div>
        </Sidebar>
        <PageContent szechenyiLogo={state?.quest?.szechenyiLogo}>
            <div className="question">
                <div className="question-text">{question?.text}</div>
                <div className="answers">
                    {question?.answerTexts?.map((answerText, index) => {
                        const correct = question?.correctAnswers?.includes(index);
                        const classNames = [
                            'answer',
                            selectedAnswerIndices?.includes(index) ? 'selected' : '',
                            state?.showDetails && !correct ? 'incorrect' : ''
                        ];

                        return <div key={index} className={classNames.join(' ')} onClick={() => handleAnswerSelection(index, state?.showDetails)}>
                            <div className="answer-text">{answerText}</div>
                            <div className="answer-index">
                                {state?.showDetails
                                    ? <FontAwesomeIcon icon={correct ? faCheck : faTimes} color="white" />
                                    : String.fromCharCode(65 + index)}
                            </div>
                        </div>;
                    })}
                </div>
            </div>
            <div style={{ marginTop: 50 }}>
                <PrimaryButton className={!selectedAnswerIndices?.length && !state?.expired ? 'disabled' : ''} loading={state?.loading} onClick={saveAnswersCallback}>Következő</PrimaryButton>
            </div>
        </PageContent>
        <Popup visible={popupVisible} onCancel={() => {}} onContinue={goToHomePage}>
            <h2 className="popup-title">{result.toFixed(0)}% helyes válasz</h2>
            <img src={winnerRatAnimation} alt="Animáció" className="popup-animation" />
            <p>Sikeresen elkészültél a küldetéssel, gratulálunk!</p>
            <button type="button" className="secondary-button" onClick={showDetails}>Válaszok megtekintése</button>
        </Popup>
    </Page>;
}
