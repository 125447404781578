import { useCallback, useEffect, useState } from 'react';
import moment from 'moment';

export function CountDown({ startTime, duration, onTick }) {
    const [currentTime, setCurrentTime] = useState(+moment.utc());
    const updateCurrentTime = useCallback(() => {
        setCurrentTime(+moment.utc());
        onTick();
    }, [onTick]);

    useEffect(() => {
        onTick();

        let interval = setInterval(updateCurrentTime, 1000);

        return () => clearInterval(interval);
    }, [onTick, updateCurrentTime]);

    const elapsedTime = currentTime - startTime;

    if (elapsedTime > duration) {
        return 'Lejárt az idő!';
    }

    return moment.duration(duration - elapsedTime, 'milliseconds').format('mm:ss', { trim: false });
}
