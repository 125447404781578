import moment from 'moment';

// eslint-disable-next-line
const emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function validateEmail(email) {
    return emailPattern.test(email);
}

export function validateBirthYear(birthYear) {
    if (/^(?:0|[1-9]\d{0,3})$/.test(birthYear)) {
        const parsedBirthYear = +birthYear;

        return parsedBirthYear <= moment().year();
    }

    return false;
}
