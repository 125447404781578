import { Redirect, Route } from 'react-router-dom';

import { useUser } from '../hooks/useUser';

export function ProtectedRoute({ children, ...props }) {
    const user = useUser();

    return <Route {...props} render={({ location }) =>
        user
            ? children
            : <Redirect to={{ pathname: '/login', state: { referrer: location.pathname } }} />} />;
}
