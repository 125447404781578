import { useCallback, useState } from 'react';
import groupBy from 'lodash/groupBy';

import { Dialog } from './Dialog';
import { SecondaryPanel } from './Panel';
import { Column, Row } from './Row';

const accentMapping = {
    'á': 'a',
    'é': 'e',
    'í': 'i',
    'ó': 'o', 'ö': 'o', 'ő': 'o',
    'ú': 'u', 'ü': 'u', 'ű': 'u'
};

function replaceAccents(string) {
    return string.replace(/[áéíóöőúüű]/g, accent => accentMapping[accent]);
}

function tokenize(string) {
    if (!string) {
        return [];
    }

    return Array.from(new Set(replaceAccents(string.trim().toLowerCase()).split(/[^a-z0-9]/)));
}

export function SearchDialog({ items, groupingKey, value, onChange, placeholder, ...props }) {
    const [searchTerm, setSearchTerm] = useState('');
    const handleSearchTermChange = useCallback(event => setSearchTerm(event.target.value), []);
    const minSearchTermLength = 3;
    const searchTermTokens = tokenize(searchTerm);
    const results = searchTerm.length >= minSearchTermLength
        ? items.filter(item => searchTermTokens.every(token => item.terms.some(term => term.includes(token))))
        : [];
    const resultGroups = groupBy(results, groupingKey ?? 'id');

    return <Dialog {...props}>
        <SecondaryPanel>
            <Row style={{ marginBottom: 15 }}>
                <Column span={1}>
                    <input type="text" placeholder={placeholder} value={searchTerm} onChange={handleSearchTermChange} />
                </Column>
            </Row>
            {searchTerm.length < minSearchTermLength
                ? `Minimum ${minSearchTermLength} karakter beírása szükséges a kereséshez!`
                : results.length
                    ? <div className="search-result-container">
                        {Object.entries(resultGroups)
                            .flatMap((entry, groupIndex) => {
                                return entry[1].map((result, resultIndex) => {
                                    const selected = value?.id ? value.id === result.id : value?.text === result.text;
                                    const classNames = [
                                        'search-result',
                                        selected ? 'selected' : ''
                                    ];
                                    const first = resultIndex === 0;

                                    if (!result.id) {
                                        console.log(result);
                                    }

                                    return [
                                        groupingKey && first ? <div key={'group-' + groupIndex} className="search-result__group-header">{entry[0]}</div> : null,
                                        <div key={'result-' + result.id} className={classNames.join(' ')} onClick={() => onChange(result)} title={result.text}>{result.text}</div>
                                    ];
                                });
                            })}
                    </div>
                    : 'Nincs találat!'}
        </SecondaryPanel>
    </Dialog>;
}
