import { Dialog } from './Dialog';
import { PrimaryButton } from './PrimaryButton';
import { Row, Column } from './Row';

export function Popup({ onContinue, children, ...props }) {
    return <Dialog {...props}>
        <div className="popup">
            <div className="popup-content">{children}</div>
            <div className="floating-button-container">
                <Row>
                    <Column>
                        <PrimaryButton onClick={onContinue}>Tovább</PrimaryButton>
                    </Column>
                </Row>
            </div>
        </div>
    </Dialog>;
}
