import { Page, PageContent } from './Page';
import { Sidebar } from '../Sidebar';
import { PrimaryPanel } from '../Panel';
import { PrimaryButton } from '../PrimaryButton';

import logo from '../../assets/mvm-logo.png';

export function FAQPage() {
    return <Page>
        <Sidebar>
            <h1>GYIK</h1>
            <img src={logo} alt="V-GO logó" />
        </Sidebar>
        <PageContent noPadding>
            <PrimaryPanel style={{ width: 750 }}>
                <p>A Gyakran Ismételt Kérdések (GYIK) között az applikációval, és a küldetésekkel kapcsolatban találsz hasznos információkat.</p>
                <p>Kattints a részletekért!</p>
                <p>
                    <PrimaryButton onClick={() => window.open('https://app.valuebot.io/survey/2561ba49-7120-4aa9-b313-7f58607a9373', '_blank')}>Megnézem</PrimaryButton>
                </p>
            </PrimaryPanel>
        </PageContent>
    </Page>;
}
