import { useEffect, useState } from 'react';

import { Page, PageContent } from './Page';
import { Sidebar } from '../Sidebar';
import { Row, Column } from '../Row';
import { PrimaryPanel, SecondaryPanel } from '../Panel';
import { useUser } from '../../hooks/useUser';
import { getLeaderboard } from '../functions';

import userIcon from '../../assets/user-icon.svg';

export function LeaderboardPage() {
    const user = useUser();
    const [state, setState] = useState(null);

    useEffect(() => {
        let mounted = true;

        user.getIdToken()
            .then(idToken => getLeaderboard(idToken))
            .then(newState => {
                if (mounted) {
                    setState(newState);
                }
            });

        return () => {
            mounted = false;
        };
    }, [user]);

    return <Page loading={!state}>
        <Sidebar>
            <h1>Ranglista</h1>
            <div className="profile-picture" style={{ marginBottom: 50 }}>
                <img src={userIcon} alt="Ikon" />
                <div className="floating-button-container">
                    <Row>
                        <Column>
                            <div className="name-tag">{state?.position}. helyezés</div>
                        </Column>
                    </Row>
                </div>
            </div>
            <SecondaryPanel>
                <p>A legtöbb pontokat szerzők értékes nyereményekkel gazdagodnak!</p>
                <p>Így szerezz pontokat: Küldd el minél több barátodnak a "Megosztás" menüpontban lévő kódodat, és a sikeres regisztrációjuk után pontokat kapsz!</p>
            </SecondaryPanel>
        </Sidebar>
        <PageContent noPadding>
            <PrimaryPanel title="Helyezések">
                <div className="leaderboard">
                    {state?.leaderboard?.map((item, index) =>
                        <div className="leaderboard-item">
                            <div className="leaderboard-item-position">{index + 1}.</div>
                            <div className="leaderboard-item-name">{item.lastName[0]}. {item.firstName}</div>
                            <div className="leaderboard-item-points">{item.points} pont</div>
                        </div>)}
                </div>
            </PrimaryPanel>
        </PageContent>
    </Page>;
}
