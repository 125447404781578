import { useCallback, useState } from 'react';
import { useLocation, Redirect, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

import { useSetUser, useUser } from '../../hooks/useUser';
import { Row, Column } from '../Row';
import { useFirebase } from '../../hooks/useFirebase';
import { Sidebar } from '../Sidebar';

import whiteLogo from '../../assets/white-mvm-logo.png';
import szechenyiLogo from '../../assets/szechenyi-logo.png';

const routes = [
    { path: '/', title: 'Küldetéseim' },
    { path: '/leaderboard', title: 'Ranglista' },
    { path: '/share', title: 'Megosztás' },
    { path: '/about-us', title: 'Rólunk' },
    { path: '/settings', title: 'Profilom/beállítások' },
    { path: '/sponsors', title: 'Szponzorok' },
    // { path: '/faq', title: 'GYIK' },
    // { path: '/survey', title: 'Elégedettségi kérdőív' },
    // { path: '/support', title: 'Helpdesk' },
];

function Menu() {
    const { auth } = useFirebase();
    const setUser = useSetUser();
    const handleLogout = useCallback(event => {
        event.preventDefault();
        auth.signOut()
            .then(() => setUser(null));
    }, [auth, setUser]);
    const [open, setOpen] = useState(false);
    const openMenu = useCallback(event => {
        event.stopPropagation();
        setOpen(true);
    }, []);
    const location = useLocation();
    const classNames = [
        'menu-mask',
        open ? 'open' : ''
    ];
    const closeMenu = useCallback(() => setOpen(false), []);

    return <div className={classNames.join(' ')} onClick={closeMenu}>
        <div className="menu" onClick={openMenu}>
            {!open
                ? <FontAwesomeIcon icon={faBars} />
                : <>
                    <Row style={{ marginBottom: 25 }}>
                        <Column span={1}>
                            <img src={whiteLogo} alt="V-GO logó" style={{ maxWidth: '100%' }} />
                        </Column>
                    </Row>
                    <ul className="menu-item-list">
                        {routes.map((route, index) => {
                            const classNames = [
                                'menu-item',
                                location.pathname === route.path ? 'menu-item--active' : ''
                            ];

                            return <li key={index} className={classNames.join(' ')}>
                                <Link to={route.path}>{route.title}</Link>
                            </li>;
                        })}
                        <li className="menu-item">
                            <a href="#/logout" onClick={handleLogout}>Kilépés</a>
                        </li>
                    </ul>
                </>}
        </div>
    </div>;
}

export function Page({ loading, children, noScroll }) {
    const location = useLocation();
    const welcomePage = location.pathname === '/welcome';
    const firstRun = localStorage.getItem('firstRun') === null;
    const user = useUser();
    const className = ['page', noScroll ? 'no-scroll' : ''];

    if (!welcomePage && firstRun) {
        return <Redirect to={{ pathname: '/welcome', state: { referrer: location.pathname } }} />;
    }

    return <>
        {user ? <Menu /> : null}
        <div className={className.join(' ')}>
            {loading
                ? <Sidebar>
                    <h1>Betöltés</h1>
                </Sidebar>
                : children}
        </div>
    </>;
}

export function PageContent(props) {
    const classNames = ['page-content', props.noPadding ? 'no-padding' : ''];

    return <div className={classNames.join(' ')}>
        <div style={{ position: 'fixed', bottom: 0, right: 0, zIndex: 2, display: props.szechenyiLogo ? 'block' : 'none', height: 200 }}>
            <img src={szechenyiLogo} alt="Széchenyi 2020 logó" />
        </div>
        {props.children}
    </div>;
}
