import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export function PrimaryButton({ loading, className, children, ...props }) {
    const classNames = [
        'primary-button',
        className ?? ''
    ]

    return <button className={classNames.join(' ')} disabled={loading} {...props}>
        {loading ? <FontAwesomeIcon icon={faSpinner} spin className="loading-icon" /> : null}
        {children}
    </button>;
}
