import { Page, PageContent } from './Page';
import { Sidebar } from '../Sidebar';
import { PrimaryPanel } from '../Panel';
import { PrimaryButton } from '../PrimaryButton';

import logo from '../../assets/mvm-logo.png';

export function SupportPage() {
    return <Page>
        <Sidebar>
            <h1>Helpdesk</h1>
            <img src={logo} alt="V-GO logó" />
        </Sidebar>
        <PageContent noPadding>
            <PrimaryPanel style={{ width: 750 }}>
                <p>Ha már jártál a GYIK (Gyakran Ismételt Kérdések) menüpontban, de nem találtad meg a választ, kattints a "Helpdesk indítása" gombra, és tedd fel nekünk a kérdésedet!</p>
                <p>Ha tanárként lenne további kérdésed, kérjük, kattints a “Mentortanár igénylése” gombra.</p>
                <p>
                    <PrimaryButton onClick={() => window.open('https://app.valuebot.io/survey/434faa8c-4b67-41c1-b7be-7197bfebf54b', '_blank')}>Helpdesk megnyitása</PrimaryButton>
                </p>
                <p>
                    <PrimaryButton onClick={() => window.open('https://app.valuebot.io/survey/1f9406da-d04f-456c-a4c6-df7d7bce7209', '_blank')}>Mentortanár igénylése</PrimaryButton>
                </p>
            </PrimaryPanel>
        </PageContent>
    </Page>;
}
