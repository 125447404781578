import { useEffect } from 'react';

export function YouTubePlayer({ videoId, onVideoEnded }) {
    useEffect(() => {
        new window.YT.Player('player', {
            videoId,
            events: {
                onStateChange: event => {
                    if (event.data === window.YT.PlayerState.ENDED) {
                        onVideoEnded();
                    }
                }
            }
        });
    }, [videoId, onVideoEnded]);

    return <div id="player"></div>;
}
