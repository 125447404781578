import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import chunk from 'lodash/chunk';
import sortBy from 'lodash/sortBy';

import { Sidebar } from '../Sidebar';
import { Page, PageContent } from './Page';
import { Row, Column } from '../Row';
import { getUserDetails } from '../functions';
import { useUser } from '../../hooks/useUser';

import noQuestsAnimation from '../../assets/animations/no-quests.gif';
import availableQuestAnimation from '../../assets/animations/available-quest.gif';
import flagIcon from '../../assets/flag.svg';

function QuestGrid({ quests }) {
    const history = useHistory();
    const handleQuestCardClick = useCallback(questId => history.push('/video/' + questId), [history]);
    const chunkSize = 2;
    const questChunks = chunk(sortBy(quests, q => +q.global), chunkSize);

    return <div className="quest-grid">
        {questChunks.flatMap((chunk, chunkIndex) =>
            chunk.map((quest, questIndex) =>
                <div key={quest.id} className="quest-cell">
                    <div className="quest-card" onClick={() => handleQuestCardClick(quest.id)}>
                        {/* <Row style={{ marginBottom: 15 }}>
                            <Column span={1}>
                                <span className="quest-expiration">Küldetés lejár: 30 nap múlva</span>
                            </Column>
                        </Row> */}
                        <Row style={{ marginBottom: 15 }}>
                            <Column span={1}>
                                <span className="quest-title">{quest.title}</span>
                            </Column>
                        </Row>
                        <Row style={{ marginBottom: 15 }}>
                            <Column span={1}>{quest.text}</Column>
                        </Row>
                    </div>
                    <div className="quest-prize-container">
                        <span className="gift-icon"></span>
                        {' Főnyeremény: '}
                        <span className="quest-prize">Hamarosan</span>
                    </div>
                    <div className="quest-index">{chunkIndex * chunkSize + questIndex + 1}</div>
                    {!quest.global
                        ? <div className="quest-flag">
                            <img src={flagIcon} style={{ height: 25 }} alt="Zászló" />
                        </div>
                        : null}
                </div>))}
    </div>;
}

export function HomePage() {
    const user = useUser();
    const [quests, setQuests] = useState(null);

    useEffect(() => {
        let mounted = true;

        if (user) {
            user.getIdToken()
                .then(getUserDetails)
                .then(details => {
                    if (mounted) {
                        setQuests(details?.quests ?? null)
                    }
                });
        }

        return () => {
            mounted = false;
        };
    }, [user]);

    return <Page loading={!quests}>
        <Sidebar>
            <h1>Küldetések</h1>
            {!quests?.length
                ? <>
                    <Row>
                        <Column span={1}>Jelenleg nincs nyitott küldetésed, nézz vissza később!</Column>
                    </Row>
                    <Row>
                        <Column span={1}>
                            <img src={noQuestsAnimation} alt="Alvó egér animáció" />
                        </Column>
                    </Row>
                </>
                : <>
                    <Row>
                        <Column span={1}>Válaszd ki a küldetést, nézd végig a videót majd válaszolj a kérdésekre!</Column>
                    </Row>
                    <Row>
                        <Column span={1}>
                            <img src={availableQuestAnimation} alt="Animáció" />
                        </Column>
                    </Row>
                </>}
        </Sidebar>
        {quests?.length
            ? <PageContent>
                <QuestGrid quests={quests} />
            </PageContent>
            : null}
    </Page>;
}
