// export const baseUrl = 'http://localhost:5001/v-go-fdefa/europe-west1';
export const baseUrl = 'https://europe-west1-v-go-fdefa.cloudfunctions.net';

async function call(method, path, params, headers, data) {
    let url = baseUrl + path;

    if (params) {
        url += '?' + Object.entries(params).map(p => p[0] + '=' + encodeURIComponent(p[1])).join('&');
    }

    const config = {
        method,
        headers: headers ? { ...headers } : {}
    };

    if (data) {
        config.headers['Content-Type'] = 'application/json';
        config.body = JSON.stringify(data);
    }

    return fetch(url, config);
}

export async function getUserDetails(idToken) {
    const response = await call('GET', '/userDetails', null, { 'X-ID-Token': idToken });

    if (response.status === 404) {
        return null;
    }

    return response.json();
}

export async function resetPassword(email) {
    return call('POST', '/resetPassword', null, null, { email });
}

export async function saveUserDetails(idToken, userDetails) {
    const headers = idToken ? { 'X-ID-Token': idToken } : null;
    const response = await call('POST', '/userDetails', null, headers, userDetails);

    return response.json();
}

export async function updateUserDetails(idToken, userDetails) {
    const headers = { 'X-ID-Token': idToken };

    return call('PUT', '/userDetails', null, headers, userDetails);
}

export async function getLeaderboard(idToken) {
    const response = await call('GET', '/leaderboard', null, { 'X-ID-Token': idToken });

    return response.json();
}

export async function startQuest(idToken, questId) {
    return call('POST', '/startQuest', null, { 'X-ID-Token': idToken }, {
        questId
    });
}

export async function saveAnswers(idToken, questId, answers, completionTime) {
    const response = await call('POST', '/saveAnswers', null, { 'X-ID-Token': idToken }, {
        questId,
        answers,
        completionTime
    });

    return response.json();
}
