import { useCallback, useRef } from 'react';

export function Dialog({ visible, onCancel, children }) {
    const maskRef = useRef(null);
    const hide = useCallback(event => {
        if (event.target === maskRef.current) {
            onCancel();
        }
    }, [onCancel]);

    return <div ref={maskRef} className="dialog-mask" style={{ display: visible ? 'flex' : 'none' }} onClick={hide}>
        <div className="dialog-container">
            {children}
        </div>
    </div>;
}
