import { useCallback, useEffect, useReducer, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons';

import { Page, PageContent } from './Page';
import { Sidebar } from '../Sidebar';
import { PrimaryPanel, SecondaryPanel } from '../Panel';
import { Row, Column } from '../Row';
import { validateEmail, validateBirthYear } from '../../utilities';
import { CitySearchDialog } from '../CitySearchDialog';
import { SchoolSearchDialog } from '../SchoolSearchDialog';
import { PrimaryButton } from '../PrimaryButton';
import { baseUrl, getUserDetails, updateUserDetails } from '../functions';
import { useUser } from '../../hooks/useUser';
import { useFirebase } from '../../hooks/useFirebase';

import userIcon from '../../assets/user-icon.svg';

const ActionType = {
    SET_EMAIL: 'SET_EMAIL',
    SHOW_CITY_SEARCH_DIALOG: 'SHOW_CITY_SEARCH_DIALOG',
    HIDE_CITY_SEARCH_DIALOG: 'HIDE_CITY_SEARCH_DIALOG',
    SET_CITY: 'SET_CITY',
    SET_TYPE: 'SET_TYPE',
    SHOW_SCHOOL_SEARCH_DIALOG: 'SHOW_SCHOOL_SEARCH_DIALOG',
    HIDE_SCHOOL_SEARCH_DIALOG: 'HIDE_SCHOOL_SEARCH_DIALOG',
    SET_SCHOOL: 'SET_SCHOOL',
    SET_CLASS: 'SET_CLASS',
    SET_NEWSLETTER: 'SET_NEWSLETTER',
    SET_ERROR: 'SET_ERROR',
    START_SAVING: 'START_SAVING',
    SET_INITIAL_DATA: 'SET_INITIAL_DATA',
    STOP_SAVING: 'STOP_SAVING',
    SET_CONSENT: 'SET_CONSENT',
    SET_CONSENT_URL: 'SET_CONSENT_URL',
    SET_BIRTH_YEAR: 'SET_BIRTH_YEAR',
};
const defaultError = 'Váratlan hiba, kérlek próbáld újra később!';

function validateState(state) {
    if (!validateBirthYear(state.birthYear)) {
        return 'Az születési év érvénytelen!';
    }
    else if (!state.email.trim()) {
        return 'Az e-mail cím mező kitöltése kötelező!';
    }
    else if (!validateEmail(state.email)) {
        return 'Az e-mail cím érvénytelen!';
    }
    else if (!state.city.text) {
        return 'A település mező kitöltése kötelező!';
    }
    else if (state.type.value === 'STUDENT' && !state.school.value) {
        return 'Az iskola mező kitöltése kötelező!';
    }

    return null;
}

function reduceAction(state, action) {
    switch (action.type) {
        case ActionType.SET_EMAIL:
            return {
                ...state,
                email: action.email
            };

        case ActionType.SET_PASSWORD:
            return {
                ...state,
                password: action.password
            };

        case ActionType.SHOW_CITY_SEARCH_DIALOG:
            return {
                ...state,
                citySearchDialogVisible: true
            };

        case ActionType.HIDE_CITY_SEARCH_DIALOG:
            return {
                ...state,
                citySearchDialogVisible: false
            };

        case ActionType.SET_CITY:
            return {
                ...state,
                city: action.city,
                citySearchDialogVisible: false
            };

        case ActionType.SHOW_SCHOOL_SEARCH_DIALOG:
            return {
                ...state,
                schoolSearchDialogVisible: true
            };

        case ActionType.HIDE_SCHOOL_SEARCH_DIALOG:
            return {
                ...state,
                schoolSearchDialogVisible: false
            };

        case ActionType.SET_SCHOOL:
            return {
                ...state,
                school: action.school,
                schoolSearchDialogVisible: false
            };

        case ActionType.SET_TYPE:
            return {
                ...state,
                type: action.typeValue
            };

        case ActionType.SET_CLASS:
            return {
                ...state,
                class: action.class
            };

        case ActionType.SET_NEWSLETTER:
            return {
                ...state,
                newsletter: action.newsletter
            };

        case ActionType.SET_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error ?? defaultError
            };

        case ActionType.START_SAVING:
            return {
                ...state,
                saving: true,
                error: null
            };

        case ActionType.STOP_SAVING:
            return {
                ...state,
                saving: false
            };

        case ActionType.SET_CONSENT:
            return {
                ...state,
                consent: action.consent
            };

        case ActionType.SET_CONSENT_URL:
            return {
                ...state,
                consentUrl: action.consentUrl
            };

        case ActionType.SET_BIRTH_YEAR:
            return {
                ...state,
                birthYear: action.birthYear,
            };

        case ActionType.SET_INITIAL_DATA:
            return {
                ...state,
                loading: false,
                userId: action.data.userId,
                lastName: action.data.lastName,
                firstName: action.data.firstName,
                email: action.data.email,
                city: action.data.city,
                type: action.data.type,
                school: action.data.school,
                class: action.data.class,
                newsletter: action.data.newsletter,
                points: action.data.points,
                completedQuestCount: action.data.completedQuestCount,
                consentUrl: action.data.consentUrl,
                completedQuests: action.data.completedQuests,
                birthYear: action.data.birthYear,
            };

        default:
            throw new Error('Unexpected action type ' + action.type);
    }
}

const initialState = {
    loading: true,
    saving: false,
    error: null,
    email: '',
    password: '',
    citySearchDialogVisible: false,
    city: null,
    type: null,
    school: null,
    schoolSearchDialogVisible: false,
    class: null,
    newsletter: false,
    points: 0,
    completedQuestCount: 0,
    consent: null,
    birthYear: '',
}

export function SettingsPage() {
    const [state, dispatch] = useReducer(reduceAction, initialState);
    const setEmail = useCallback(event => dispatch({ type: ActionType.SET_EMAIL, email: event.target.value }), []);
    const showCitySearchDialog = useCallback(() => dispatch({ type: ActionType.SHOW_CITY_SEARCH_DIALOG }), []);
    const hideCitySearchDialog = useCallback(() => dispatch({ type: ActionType.HIDE_CITY_SEARCH_DIALOG }), []);
    const setCity = useCallback(city => dispatch({ type: ActionType.SET_CITY, city }), []);
    const showSchoolSearchDialog = useCallback(() => dispatch({ type: ActionType.SHOW_SCHOOL_SEARCH_DIALOG }), []);
    const hideSchoolSearchDialog = useCallback(() => dispatch({ type: ActionType.HIDE_SCHOOL_SEARCH_DIALOG }), []);
    const setSchool = useCallback(school => dispatch({ type: ActionType.SET_SCHOOL, school }), []);
    const setType = useCallback(event => dispatch({ type: ActionType.SET_TYPE, typeValue: { value: event.target.value, text: event.target.selectedOptions[0].innerHTML } }), []);
    const setClass = useCallback(event => dispatch({ type: ActionType.SET_CLASS, class: { value: event.target.value, text: event.target.selectedOptions[0].innerHTML } }), []);
    // const setNewsletter = useCallback(event => dispatch({ type: ActionType.SET_NEWSLETTER, newsletter: event.target.checked }), []);
    const setError = useCallback(error => dispatch({ type: ActionType.SET_ERROR, error }), []);
    const startSaving = useCallback(() => dispatch({ type: ActionType.START_SAVING }), []);
    const setInitialData = useCallback(data => dispatch({ type: ActionType.SET_INITIAL_DATA, data }), []);
    const stopSaving = useCallback(() => dispatch({ type: ActionType.STOP_SAVING }), []);
    const setConsent = useCallback(event => dispatch({ type: ActionType.SET_CONSENT, consent: event.target.files[0] }), []);
    const setConsentUrl = useCallback(consentUrl => dispatch({ type: ActionType.SET_CONSENT_URL, consentUrl }), []);
    const setBirthYear = useCallback(event => dispatch({ type: ActionType.SET_BIRTH_YEAR, birthYear: event.target.value }), []);
    const user = useUser();
    const fileInputRef = useRef(null);
    const openFileBrowser = useCallback(() => fileInputRef.current.click(), []);
    const { storage } = useFirebase();
    const handleSubmit = useCallback(async event => {
        event.preventDefault();

        let error;

        if ((error = validateState(state))) {
            setError(error);
            return;
        }

        startSaving();

        const idToken = await user.getIdToken();
        const student = state.type.value === 'STUDENT';

        try {
            await updateUserDetails(idToken, {
                lastName: state.lastName,
                firstName: state.firstName,
                email: user.providerData[0].providerId === 'password' ? state.email : undefined,
                city: state.city.text,
                type: state.type,
                school: student ? { value: state.school.value, name: state.school.text } : null,
                class: student ? { value: +state.class.value, text: state.class.text } : null,
                newsletter: state.newsletter,
                birthYear: state.birthYear,
            });

            if (state.consent) {
                const ref = storage.ref().child(`consents/${ state.userId }`);

                await ref.put(state.consent);
                setConsentUrl(await ref.getDownloadURL());
            }

            stopSaving();
        }
        catch (error) {
            setError();
        }
    }, [state, startSaving, user, setError, storage, stopSaving, setConsentUrl]);
    const noScroll = state.citySearchDialogVisible || state.schoolSearchDialogVisible;

    useEffect(() => {
        let mounted = true;

        user.getIdToken()
            .then(idToken => getUserDetails(idToken))
            .then(async details => {
                let consentUrl = null;

                try {
                    consentUrl = await storage.ref().child(`consents/${ details.userId }`).getDownloadURL();
                }
                catch (error) {
                    if (error.code !== 'storage/object-not-found') {
                        throw error;
                    }
                }

                if (mounted) {
                    setInitialData({
                        userId: details.userId,
                        lastName: details.lastName,
                        firstName: details.firstName,
                        email: user.email,
                        city: { text: details.city },
                        type: details.type,
                        school: { value: details.school.value, text: details.school.name },
                        class: details.class,
                        newsletter: details.newsletter,
                        points: details.points,
                        completedQuestCount: details.completedQuestCount,
                        consentUrl,
                        completedQuests: details.completedQuests,
                        birthYear: details.birthYear?.toString(),
                    });
                }
            });

        return () => {
            mounted = false;
        };
    }, [user, setInitialData, storage]);

    return <Page loading={state.loading} noScroll={noScroll}>
        <Sidebar>
            <h1>Profilom</h1>
            <div className="profile-picture">
                <img src={userIcon} alt="Ikon" />
                <div className="floating-button-container">
                    <Row>
                        <Column>
                            <div className="name-tag">{state.lastName} {state.firstName}</div>
                        </Column>
                    </Row>
                </div>
            </div>
            <Row>
                <Column span={1}>
                    <h2>{state.points} pont</h2>
                </Column>
                <Column span={1}>
                    <h2>{state.completedQuestCount} küldetés</h2>
                </Column>
            </Row>
            <Row>
                <Column span={1}>
                    <h3>Pontjaim</h3>
                </Column>
                <Column span={1}>
                    <h3>Elvégzett küldetéseim</h3>
                </Column>
            </Row>
        </Sidebar>
        <PageContent noPadding>
            <PrimaryPanel title="Beállítások">
                {state.error ? <p className="error-message">{state.error}</p> : null}
                <SecondaryPanel>
                    <form onSubmit={handleSubmit}>
                        <Row style={{ marginBottom: 15 }}>
                            <Column span={1}>
                                <input type="text" placeholder="Vezetéknév" value={state.lastName} disabled />
                            </Column>
                            <Column span={1}>
                                <input type="text" placeholder="Keresztnév" value={state.firstName} disabled />
                            </Column>
                        </Row>
                        <Row style={{ marginBottom: 15 }}>
                            <Column span={1}>
                                <input type="text" placeholder="Születési év" value={state.birthYear} onChange={setBirthYear} />
                            </Column>
                        </Row>
                        <Row style={{ marginBottom: 15 }}>
                            <Column span={1}>
                                <input type="text" placeholder="E-mail cím" disabled={user.providerData[0].providerId !== 'password'} value={state.email} onChange={setEmail} />
                            </Column>
                        </Row>
                        <Row style={{ marginBottom: 15 }}>
                            <Column span={1}>
                                <input type="text" placeholder="Település" value={state.city?.text ?? ''} readOnly onFocus={showCitySearchDialog} />
                            </Column>
                        </Row>
                        <Row style={{ marginBottom: 15 }}>
                            <Column span={1}>
                                <select value={state?.type?.value} onChange={setType}>
                                    <option value="STUDENT">Diák vagyok</option>
                                    <option value="TEACHER">Tanár vagyok</option>
                                    <option value="OTHER">Egyéb</option>
                                </select>
                            </Column>
                        </Row>
                        {state?.type?.value === 'STUDENT'
                            ? <>
                                <Row style={{ marginBottom: 15 }}>
                                    <Column span={1}>
                                        <input type="text" placeholder="Iskola" value={state.school?.text ?? ''} readOnly onFocus={showSchoolSearchDialog} />
                                    </Column>
                                </Row>
                                <Row style={{ marginBottom: 15 }}>
                                    <Column span={1}>
                                        <select value={state?.class?.value} onChange={setClass}>
                                            <option value="0">Alsó tagozat (1-4. osztály)</option>
                                            <option value="1">Felső tagozat (5-8. osztály)</option>
                                            <option value="2">Középiskola</option>
                                        </select>
                                    </Column>
                                </Row>
                            </>
                            : null}
                        <Row style={{ marginBottom: 15 }}>
                            <Column span={1} style={{ flexDirection: 'column' }}>
                                <button type="button" className="input-button" onClick={openFileBrowser} style={{ marginBottom: 15 }}>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', paddingRight: 15 }}>
                                            {state?.consent ? state?.consent?.name : 'Aláírt szülői nyilatkozat feltöltése'}
                                        </div>
                                        <div style={{ flexGrow: 1, textAlign: 'right' }}>
                                            <FontAwesomeIcon icon={faFolderOpen} />
                                        </div>
                                    </div>
                                </button>
                                <div>Az aláírt szülői nyilatkozatot képfájlként tudod feltölteni, max. 5 MB méretben.</div>
                                <div style={{ marginTop: 15 }}>
                                    A szülői nyilatkozatmintát <a href="https://www.brizy.cloud/customfile/77f1e5f7e6de5a6ef9d6c6f6851bd9fd.pdf" target="_blank" rel="noreferrer" style={{ color: '#3CA874' }}>ide kattintva</a> tudod letölteni.
                                </div>
                                <input accept="image/jpeg" type="file" style={{ display: 'none' }} ref={fileInputRef} onChange={setConsent} />
                            </Column>
                        </Row>
                        {/* <Row style={{ marginBottom: 15 }}>
                            <Column span={1}>
                                <label>
                                    <input type="checkbox" checked={state.newsletter} onChange={setNewsletter} />
                                    {' Hozzájárulok, hogy hírleveleket kapjak a megadott e-mail címemre!'}
                                </label>
                            </Column>
                        </Row> */}
                        <Row>
                            <Column span={1}>
                                <PrimaryButton loading={state.saving}>Mentés</PrimaryButton>
                            </Column>
                        </Row>
                    </form>
                </SecondaryPanel>
                {state?.completedQuests?.length
                    ? <>
                        <div style={{ marginTop: 30 }}>Küldetésekhez tartozó oklevelek letöltése</div>
                        {state?.completedQuests.map((completedQuest, key) =>
                            <PrimaryButton key={key} className="primary-button--white" style={{ marginTop: 15 }} onClick={() => window.open(baseUrl + `/generateCertificate?userId=${ state?.userId }&questId=${ completedQuest.id }`, '_blank')}>{completedQuest.title}</PrimaryButton>)}
                    </>
                    : null}
            </PrimaryPanel>
        </PageContent>
        <CitySearchDialog visible={state.citySearchDialogVisible} onCancel={hideCitySearchDialog} value={state.city} onChange={setCity} />
        <SchoolSearchDialog visible={state.schoolSearchDialogVisible} onCancel={hideSchoolSearchDialog} value={state.school} onChange={setSchool} />
    </Page>;
}
