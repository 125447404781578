import { useEffect } from 'react';
import { useLocation, useHistory, Redirect } from 'react-router-dom';

import { Page } from './Page';
import { Sidebar } from '../Sidebar';

import welcomeAnimation from '../../assets/animations/welcome.gif';

export function WelcomePage() {
    const location = useLocation();
    const referrer = location?.state?.referrer ?? '/login';
    const history = useHistory();
    const firstRun = localStorage.getItem('firstRun') === null;

    useEffect(() => {
        let timeout;

        if (firstRun) {
            // to-do: adjust timeout to the length of the animation
            timeout = setTimeout(() => {
                localStorage.setItem('firstRun', 'false');
                history.push(referrer);
            }, 6000);
        }

        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [firstRun, history, referrer]);

    if (!firstRun) {
        return <Redirect to={referrer} />;
    }

    return <Page>
        <Sidebar style={{ padding: 0 }}>
            <img src={welcomeAnimation} alt="Üdvözlő animáció" />
        </Sidebar>
    </Page>;
}
