import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import { useMemo, useCallback } from 'react';

const firebaseConfig = {
    apiKey: 'AIzaSyDZlWOXhuQdGe6VWoJth6c9fK5y8-4VbgY',
    authDomain: 'v-go-fdefa.firebaseapp.com',
    projectId: 'v-go-fdefa',
    storageBucket: 'v-go-fdefa.appspot.com',
    messagingSenderId: '141064391406',
    appId: '1:141064391406:web:a9d927b1f0ff2d0e0f01ff'
};

if (!firebase.apps.length) {
     firebase.initializeApp(firebaseConfig);
}

export function useFirebase() {
    const auth = useMemo(() => {
        const auth = firebase.auth();

        // auth.useEmulator("http://localhost:9099", { disableWarnings: true });

        auth.languageCode = 'hu';

        return auth;
    }, []);
    const login = useCallback(async (email, password) =>
        auth.signInWithEmailAndPassword(email, password)
            .then((userCredential) => userCredential.user), [auth]);
    const loginWithGoogle = useCallback(async () => {
        const provider = new firebase.auth.GoogleAuthProvider();

        return auth.signInWithPopup(provider)
            .then(userCredential => userCredential.user);
    }, [auth]);
    const loginWithFacebook = useCallback(async () => {
        const provider = new firebase.auth.FacebookAuthProvider();

        return auth.signInWithPopup(provider)
            .then(userCredential => userCredential.user);
    }, [auth]);
    const storage = useMemo(() => {
        const storage = firebase.storage();

        // storage.useEmulator('localhost', 9199);

        return storage;
    }, []);

    return {
        auth,
        login,
        loginWithGoogle,
        loginWithFacebook,
        storage
    };
}
