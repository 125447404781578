import { useCallback, useEffect, useState } from 'react';

import { Page } from './Page';
import { Sidebar } from '../Sidebar';
import { Row, Column } from '../Row';
import { useUser } from '../../hooks/useUser';
import { getUserDetails } from '../functions';
import { PrimaryButton } from '../PrimaryButton';

import shareIcon from '../../assets/share-icon.svg';

export function SharePage() {
    const user = useUser();
    const [inviteCode, setInviteCode] = useState(null);
    const copyInviteCode = useCallback(() => navigator.clipboard.writeText(inviteCode), [inviteCode]);

    useEffect(() => {
        let mounted = true;

        user.getIdToken()
            .then(idToken => getUserDetails(idToken))
            .then(details => {
                if (mounted) {
                    setInviteCode(details.inviteCode);
                }
            });

        return () => {
            mounted = false;
        };
    }, [user]);

    return <Page loading={!inviteCode}>
        <Sidebar>
            <h1>Megosztás</h1>
            <img src={shareIcon} alt="Ikon" />
            <h2>A gombra kattintással kimásolod a kódodat, amit így már elég csak elküldened a barátaidnak.</h2>
            <div>
                <Row>
                    <Column>
                        <PrimaryButton onClick={copyInviteCode}>{inviteCode}</PrimaryButton>
                    </Column>
                </Row>
            </div>
            <p>Ha a regisztráció során megadják a kódodat és sikeresen regisztrálnak, akkor 1 pontot jóváírunk neked!</p>
        </Sidebar>
    </Page>;
}
