import { useCallback, useReducer } from 'react';
import { useHistory } from 'react-router-dom';

import { Page, PageContent } from './Page';
import { Sidebar } from '../Sidebar';
import { PrimaryPanel } from '../Panel';
import { Row, Column } from '../Row';
import { resetPassword } from '../functions';
import { PrimaryButton } from '../PrimaryButton';

import logo from '../../assets/mvm-logo.png';

const ActionType = {
    SET_EMAIL: 'SET_EMAIL',
    START_LOADING: 'START_LOADING',
    SET_ERROR: 'SET_ERROR',
    SET_EMAIL_SENT: 'SET_EMAIL_SENT'
};
const defaultError = 'Váratlan hiba, kérlek próbáld újra később!';

function reduceAction(state, action) {
    switch (action.type) {
        case ActionType.SET_EMAIL:
            return {
                ...state,
                email: action.email
            };

        case ActionType.START_LOADING:
            return {
                ...state,
                loading: true,
                error: null
            };

        case ActionType.SET_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error ?? defaultError
            };

        case ActionType.SET_EMAIL_SENT:
            return {
                ...state,
                loading: false,
                error: null,
                emailSent: true
            };

        default:
            throw new Error('Unexpected action type ' + action.type);
    }
}

const initialState = {
    loading: false,
    error: null,
    email: '',
    emailSent: false
};

export function PasswordResetPage() {
    const [state, dispatch] = useReducer(reduceAction, initialState);
    const startLoading = useCallback(() => dispatch({ type: ActionType.START_LOADING }), []);
    const setError = useCallback(error => dispatch({ type: ActionType.SET_ERROR, error }), []);
    const setEmail = useCallback(event => dispatch({ type: ActionType.SET_EMAIL, email: event.target.value }), []);
    const setEmailSent = useCallback(() => dispatch({ type: ActionType.SET_EMAIL_SENT }), []);
    const initiatePasswordReset = useCallback(async event => {
        event.preventDefault();

        if (!state.email.trim()) {
            setError('Az e-mail cím mező kitöltése kötelező!');
            return;
        }

        startLoading();

        const response = await resetPassword(state.email);

        if (!response.ok) {
            setError();
        }
        else {
            setEmailSent();
        }
    }, [state.email, setError, startLoading, setEmailSent]);
    const history = useHistory();
    const goToLoginPage = useCallback(() => history.push('/login'), [history]);

    return <Page>
        <Sidebar>
            <img src={logo} alt="V-GO logó" />
        </Sidebar>
        <PageContent>
            <PrimaryPanel title="Jelszó visszaállítása">
                {state.error ? <p className="error-message">{state.error}</p> : null}
                {!state.emailSent
                    ? <form onSubmit={initiatePasswordReset}>
                        <Row style={{ marginBottom: 30 }}>
                            <Column span={1}>
                                <input type="text" placeholder="E-mail cím" value={state.email} onChange={setEmail} />
                            </Column>
                        </Row>
                        <Row style={{ marginBottom: 30 }}>
                            <Column span={1}>Add meg az e-mail címedet, hogy kiküldhessük a jelszavad visszaállításához szükséges lépéseket!</Column>
                        </Row>
                        <Row style={{ marginBottom: 30 }}>
                            <Column span={1}>
                                <PrimaryButton loading={state.loading}>Küldés</PrimaryButton>
                            </Column>
                        </Row>
                    </form>
                    : <>
                        <Row style={{ marginBottom: 15 }}>
                            <Column span={1}>A jelszó visszaállításhoz szükséges lépések sikeresen kiküldve amennyiben a megadott e-mail cím megtalálható volt a rendszereinkben!</Column>
                        </Row>
                        <Row style={{ marginBottom: 30 }}>
                            <Column span={1}>Végezed el őket majd térj vissza a belejentkezéshez az alábbi gombbal.</Column>
                        </Row>
                        <Row style={{ marginBottom: 30 }}>
                            <Column span={1}>
                                <PrimaryButton onClick={goToLoginPage}>Bejelentkezés</PrimaryButton>
                            </Column>
                        </Row>
                    </>}
            </PrimaryPanel>
        </PageContent>
    </Page>;
}

